<template>
    <vFormPage>
        <div slot="header" class="header">
            <img :src="sInfo.spic" />
        </div>
        <div class="signin-body">
            <div class="form-title-wrapper">
                <div class="logo">
                    <img v-show="sInfo.slogo" :src="sInfo.slogo" alt="Logo" />
                </div>
                <div class="title">{{ sInfo.sname }}</div>
                <div class="title-info">
                    <p>Collect Points,</p>
                    <p>Get Unlimited Gifts!</p>
                </div>
            </div>
            <vForm class="form" ref="form" :model="formInfo" :rules="rules">
                <vFormItem label="Enter your mobile number & click to get code" prop="phoneNum">
                    <div class="flex" :class="phoneNumInvalid ? 'error-border' : '' ">
                        <vue-tel-input v-model="formInfo.phoneNum" v-bind="phoneProps" @country-changed="setPhonePrefix" @blur="handleBlur">
                            <template slot="arrow-icon" slot-scope="{ open }">
                                <span class="tel-arrow">{{ open ? '▲' : '▼' }}</span>
                            </template>
                        </vue-tel-input>
                    </div>
                </vFormItem>
                <div id="robot" style="margin: 20px 0;"></div>
                <vFormItem label="Verify code" prop="phoneCode" class="code-wrapper">
                    <div class="flex">
                        <vFormInput class="code-input" v-model.trim="formInfo.phoneCode" />
                        <vButton
                            :text="codeText"
                            type="primary-o-bold"
                            :code="codeType"
                            :clickBefore="verifyBefore"
                            @btnClick="getCode"
                            :stopTimer="stopTimer"
                        />
                    </div>
                </vFormItem>
                <div class="footer-info" id="form-footer">Please enter correct number, we will notify you via text message</div>
                <div class="btn-wrapper">
                    <vButton id="btn-join" :text="is_loading ? 'Joining in ...' : 'Join'" @btnClick="signin" />
                </div>
                <div class="footer-info" id="form-footer-additional">
                    <p>For non-member guests, by joining,</p>
                    <p>you consent to activate the membership of the store</p>
                </div>
            </vForm>
        </div>
        <div slot="body-footer">
            <div class="footer-info">By joining in, you agree to Minitable Tech, Inc</div>
            <div class="footer-info">
                <a href="http://info.minitable.net/termsofuse.html" target="_blank">Terms of Service</a>
                and
                <a href="http://info.minitable.net/privacypolicy.html" target="_blank">Privacy Policy</a>
            </div>
            <div class="footer-info">
                <span>Powered by </span>
                <span>
                    <img src="../../assets/images/footer_logo.svg" />
                </span>
            </div>
        </div>
    </vFormPage>
</template>

<style>
.vti__dropdown-list {
    width: 2rem;
}
/* .iti__country-list {
    max-width: 240px !important;
    overflow-y: hidden !important;
}
.iti-mobile .iti--container {
    top: 2.2rem !important;
    bottom: 0.2rem !important;
    left: 0.42rem !important;
    right: 0.2rem !important;
    font-size: 14px !important;
}

.iti-mobile .iti__country-list {
    width: 200% !important;
}

.iti-mobile .iti__country {
    padding: 10px 10px !important;
    line-height: 1.3em !important;
} */
.vue-tel-input {
    border-radius: 0.08rem;
    border: 1px solid #979797;
    height: 0.33rem;
    width: 100%;
}
.vti__input {
    border-radius: 0.08rem;
}
</style>

<style scoped>
/* 顶部样式 */
.header img {
    position: relative;
    /* top: 48px; */
    width: 100%;
    height: auto;
    z-index: 9;
}

/* 底部样式 */
.footer-info {
    text-align: center;
    /* font-size: 12px; */
    color: #aab0bb;
    margin-bottom: 5px;
    font-size: 12px;
    text-align: center;
}

.footer-info:nth-child(2) {
    margin-bottom: 10px;
}

.footer-info:nth-child(3) {
    font-size: 14px;
}

.footer-info a {
    color: #0080ff;
}
.footer-info img {
    height: 12px;
    width: auto;
    padding-left: 2px;
}
#form-footer {
    font-size: 10px;
    margin: auto;
}
#form-footer-additional p{
    font-size: 12px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 6px;
    margin-bottom: 2px;
    color: #9b9b9b;
}

/* 表单样式 */

.signin-body .footer-info {
    font-size: 12px;
    text-align: left;
    margin-bottom: 10px;
    color: #343434;
    padding-top: 5px;
}

.signin-body .form {
    margin: 40px 20px 96px 20px;
}

.signin-body .form .flex {
    display: flex;
}

.signin-body .form-item {
    font-size: 14px;
}

.signin-body .form-title-wrapper {
    margin: 24px 31px 24px 31px;
    text-align: center;
    line-height: 18px;
}

.signin-body .form-title-wrapper .title {
    font-size: 18px;
    font-weight: normal;
    line-height: 20px;
    color: #343434;
    padding-bottom: 18px;
}

.logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 76px;
    height: 76px;
    padding-bottom: 10px;
}

.logo img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.signin-body .form-title-wrapper .title-info {
    font-size: 28px;
    color: #9124c8;
    line-height: 32px;
}

.title-info p {
    margin-top: 5px;
    margin-bottom: 5px;
}

.signin-body .code-wrapper {
    margin-bottom: 0;
}

.signin-body .code-input {
    width: 60%;
}

.btn-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.btn-wrapper .btn {
    width: 166px;
    height: 0.49rem;
    line-height: 0.49rem;
    font-size: 18px;
    font-weight: bold;
}

/* .signin-body .form .flex .phone-num-input {
    width: 300px;
} */
.error-border {
    border-bottom: 1px solid red;
}
</style>
<style>
.swal2-popup {
    width: 3rem;
    height: 2rem;
    font-size: 0.1rem;
    border-radius: 0.1rem;
}
</style>

<script>
import Vue from 'vue';
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
// import { SIGN_SRC, PASS_SRC } from '../../consts/const.js';
import { isExistUser } from '../../kits/tools.js';
import vFormPage from '../../components/Formpage.vue';
import vButton from '../../components/Button.vue';
import vForm from '../../components/form/Form.vue';
import vFormItem from '../../components/form/FormItem.vue';
import vFormInput from '../../components/form/FormInput.vue';
// import intlTelInput from 'intl-tel-input';
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2);


export default {
    name: 'v-sign-in',
    components: { vFormPage, vButton, vForm, vFormItem, vFormInput, VueTelInput},
    data() {
        return {
            // SIGN_SRC, PASS_SRC,
            codeText: 'Get Code',
            codeType: true,
            phoneOptions: ['+1', '+86'],
            phoneNumInvalid: false,
            formInfo: {
                phonePrefix: '+1',
                phoneNum: '',
                phoneCode: ''
            },
            rules: {
                // phonePrefix: [{ required: true, trigger: 'blur' }],
                phoneNum: [{ required: false, trigger: 'blur' }],
                phoneCode: [{ required: true, trigger: 'blur' }]
            },
            stopTimer: false,
            // iti: undefined,
            invite_by: this.$route.query.invite_by || '',
            sid: this.$route.params.sid,
            phoneProps: {
                defaultCountry: 'US',
                onlyCountries: ['US', 'CN'],
                validCharactersOnly: true,
                inputOptions: {
                    showDialCode: false,
                    placeholder: 'xxx xxx xxxx'
                },
                dropdownOptions: {
                    showDialCodeInList: true,
                    showDialCodeInSelection: true,
                    showFlags: true,
                }
            },
            is_human: '',
            human_token: '',
        };
    },
    // beforeCreate() {
    //     let script = document.createElement('script');
    //     script.type = 'text/javascript';
    //     script.src = '//recaptcha.net/recaptcha/api.js?render=explicit';
    //     script.async = 'async';
    //     script.defer = 'defer';
    //     document.body.appendChild(script);
    // },
    created() {
        if (this.sid && window.localStorage.getItem('token_' + this.sid)) {
            let user_info = window.localStorage.getItem('user_' + this.sid) || '';
            if (user_info) {
                user_info = JSON.parse(user_info);
                user_info.sid = this.sid;

                this.updateUser(user_info);
                this.initsInfo({postBody:{ data: { sid: this.sid } },router:this.$router});

                this.$router.replace({
                    path: '/pass/' + this.sid + '/' + user_info.id
                });
                return;
            }
        }

        let isStore = this.sid;
        window.localStorage.setItem('system_' + this.sid, JSON.stringify({ isSystem: !isStore }));
        isStore && this.initsInfo({postBody:{ data: { sid: this.sid } },router:this.$router});
        this.updateUser({ home: false, sid: this.sid }); // 更新是否运行进入主页的判断
    },
    mounted() {
        this.initRecaptcha();
    },
    computed: {
        ...mapGetters({
            is_loading: 'app/getLoading',
            sInfo: 'app/getsInfo',
            user: 'app/getUser'
        })
    },
    methods: {
        ...mapActions({
            initsInfo: 'app/initsInfo',
            triggerSms: 'sms/triggerSms',
            verifySms: 'sms/verifySms',
            submitUser: 'app/submitUser',
            checkActive: 'app/checkActive',
            verifyUser: 'app/verifyUser'
        }),
        ...mapMutations({
            updateUser: 'app/SET_USER',
            setLoading: 'app/APP_SET_IF_LOADING',
            showMsg: 'app/APP_SET_MSG'
        }),
        setPhonePrefix(country) {
            // const selected = this.iti.getSelectedCountryData();
            // this.formInfo.phonePrefix = `+${selected.dialCode}`;
            this.formInfo.phonePrefix = `+${country.dialCode}`;
        },
        validatePhoneNum(phoneNum) {
            if(!phoneNum || phoneNum === '' || this.formInfo.phonePrefix === '') {
                return false;
            }
            return true;
        },
        handleBlur() {
            if(!this.validatePhoneNum(this.formInfo.phoneNum)) {
                this.phoneNumInvalid = true;
            }
            else {
                this.phoneNumInvalid = false;
            }
        },
        // sign_up(mobile) {
        //     this.submitUser({
        //         data: {
        //             phone: mobile
        //         },
        //         success: (submitRes) => {
        //             if (!submitRes || !submitRes.cid) {
        //                 // 注册成功，但是无正确注册信息返回
        //                 return;
        //             }
        //             this.updateUser({ home: true });
        //             if (!this.sid) {
        //                 console.log('no sid');
        //                 return;
        //             }
        //             this.$router.replace({
        //                 path: '/pass/' + submitRes.sid + '/' + submitRes.cid
        //             });
        //         },
        //         fail: () => {
        //             this.setLoading({ is_loading: false });
        //         }
        //     });
        // },
        signin() {
            // this.setPhonePrefix();
            this.$refs.form.validate((valid) => {
                if (!valid || this.is_loading || this.phoneNumInvalid) {
                    return;
                }
                this.setLoading({ is_loading: true });
                let phoneNumOnly = this.formInfo.phoneNum.replace(/\D/g, '');
                let mobile = `${this.formInfo.phonePrefix}${phoneNumOnly}`;
                this.verifySms({
                    data: { sid: this.sid, mobile, code: this.formInfo.phoneCode },
                    success: (resData) => {
                        this.stopTimer = true;
                        if (!resData.isValid) {
                            this.setLoading({ is_loading: false });
                            return;
                        }                    
                        this.verifyUser({
                            data: {
                                sid: this.sid,
                                sname: this.sInfo.sname
                            },
                            success: (submitRes) => {
                                if (!submitRes || !submitRes.cid) {
                                    // 注册成功，但是无正确注册信息返回
                                    return;
                                }
                                this.updateUser({ home: true, sid: this.sid });
                                // if (!this.$route.params.sid) {
                                if (!this.sid) {
                                    console.log('no sid');
                                    return;
                                }
                                this.setLoading({ is_loading: false });
                                this.$router.replace({
                                    path: '/pass/' + submitRes.sid + '/' + submitRes.cid
                                });
                            },
                            fail: () => {
                                this.setLoading({ is_loading: false });
                                // this.sign_up(mobile);
                                return;
                            }
                        });
                    },
                    fail: () => {
                        this.setLoading({ is_loading: false });
                    }
                });
            });
        },
        verifyBefore() {
            if (!this.is_human) {
                this.$swal({
                    text: 'Please verify you are a human.',
                    type: 'warning',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#d33',
                });
                return;
            }

            if (!this.formInfo.phoneNum) {
                this.$swal({
                    text: 'Please enter your phone number.',
                    type: 'warning',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#d33',
                });
                return;
            }

            let phoneNumOnly = this.formInfo.phoneNum.replace(/\D/g, '');

            if (this.formInfo.phonePrefix == '+1' && phoneNumOnly.length != 10 || this.formInfo.phonePrefix == '+86' && phoneNumOnly.length != 11) {
                this.$swal({
                    text: 'Please enter a valid phone number.',
                    type: 'warning',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#d33',
                });
                return;
            }

            return this.formInfo && this.formInfo.phoneNum !== '';
        },
        getCode() {
            // this.setPhonePrefix();
            let phoneNumOnly = this.formInfo.phoneNum.replace(/\D/g, '');

            if (!this.is_human) {
                this.$swal({
                    text: 'Please verify you are a human.',
                    type: 'warning',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: '#d33',
                });
                return;
            }

            this.triggerSms({ 
                data: { sid: this.sid, mobile: `${this.formInfo.phonePrefix}${phoneNumOnly}`, recaptcha_token: this.human_token}
            });
        },
        initRecaptcha() {
            // setTimeout(() => {
                window.grecaptcha.render("robot", {
                    'sitekey': '6LcCX8AlAAAAAExazajm9drX0FtM7ec4GEPe4SXi',
                    'theme': 'light',
                    'size': 'normal',
                    'callback': this.verifyNotRobot,
                    'expired-callback': this.expiredRobot,
                    'error-callback': this.errorRobot
                });
            // }, 500);
        },
        verifyNotRobot(args) {
            this.is_human = 1;
            this.human_token = args;
        },
        expiredRobot() {
            this.is_human = 0;
            this.human_token = '';
        },
        errorRobot(err) {
            this.is_human = 0;
            this.human_token = '';
        },
    }
};
</script>