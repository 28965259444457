<template>
    <div :class="setClass()" :id="setId()" ref='refbtn' @click.prevent="handleClick">{{code ? codeText : text}}</div>
</template>
<style>
    .btn {
        width: 302px;
        height: 33px;
        border-radius: 8px;
        font-size: 14px;
        margin-top: 15px;
        text-align: center;
        line-height: 33px;
        overflow: hidden;
    }

    .btn.primary {
        background-color: #9124C8;
        color: #FFFFFF;
    }

    .btn.primary-o {
        background-color: #FFFFFF;
        color: #9124C8;
        border: 1px solid;
    }

    .btn.danger-o {
        background-color: #FFFFFF;
        color: red;
        border: 1px solid;
    }

    .btn.primary-o-bold {
        background-color: #FFFFFF;
        color: #9124C8;
        border: 1px solid;
        font-weight: bold;
        border-radius: 8px;
        margin-top: 0px;
        width: 50%;
        margin-left: 5%;
    }

    .btn.disabled.primary {
        background-color: #aaa;
    }

    .btn.disabled.primary-o,
    .btn.disabled.primary-o-bold {
        color: #aaa;
    }
</style>


<script>
    import Vue from 'vue';

    export default {
        name: 'v-btn',
        components: {},
        props: {
            text: { type: String, default: '' },
            type: { type: String, default: 'primary' },
            code: { type: Boolean, default: false },
            clickBefore: { type: Function, default: () => { return true; } },
            stopTimer: { type: Boolean, default: false },
        },
        data() {
            return {
                codeText: this.text,
                timer: null,
                codeDisabled: {}
            }
        },
        created() {
        },
        mounted() {
        },
        computed: {
        },
        updated() {
            if (this.stopTimer) {
                this.closeTimer();
            }
        },
        beforeDestroy() {
            this.closeTimer();
        },
        methods: {
            closeTimer() {
                if (this.timer) {
                    clearInterval(this.timer);
                    this.timer = null;
                    this.codeDisabled = {};
                    this.codeText = this.text;
                }
            },
            setId() {
                return this.$refs.refbtn && this.$refs.refbtn.id ? this.$refs.refbtn.id : `${new Date().valueOf()}`;
            },
            setClass() {
                return `btn ${!this.type || this.type === 'primary' ? 'primary' : ''} 
                ${this.type === 'primary-o' ? 'primary-o' : ''} 
                ${this.type === 'danger-o' ? 'danger-o' : ''} 
                ${this.type === 'primary-o-bold' ? 'primary-o-bold' : ''}
                ${this.code && this.$refs.refbtn && this.codeDisabled && this.codeDisabled[this.$refs.refbtn.id] ? 'disabled' : ''}`;
            },
            emitClick() {
                this.$emit('btnClick');
                this.codeDisabled[this.$refs.refbtn.id] = true;
                let time = 59;
                this.timer = setInterval(() => {
                    if (time == 0) {
                        this.closeTimer();
                        return;
                    } else {
                        this.codeText = time + '(s) again';
                        time--;
                    }
                }, 1000);
            },
            handleClick() {
                let tobeCon = this.clickBefore();
                if (!tobeCon) {
                    return;
                }
                if (!this.code) {
                    this.$emit('btnClick');
                    return;
                }
                if (this.code && this.codeDisabled[this.$refs.refbtn.id]) {
                    return;
                }
                this.emitClick();
            }
        }
    };
</script>