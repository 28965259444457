<template>
    <input class="input-normal" ref="input" :type="type" :value="currentValue" @input="handleInput"
        @blur="handleBlur" />
</template>
<style>
    .input-normal {
        height: 33px;
        font-size: 14px;
        outline-style: none;
        border: 1px solid #979797;
        border-radius: 8px;
        padding: 0 4px;
        background-color: #FAFAFA
    }
    .form label {
        display: block;
        padding-bottom: 5px;
        color: #343434 !important;
    }
    .iti {
        width: 80px;
    }
</style>
<script>
    import Emitter from '../../mixins/emitter.js';
    export default {
        name: 'v-input',
        mixins: [Emitter],
        props: {
            type: { type: String, default: 'text' },
            value: { default: '' },
            isRequired: { type: Boolean, default: false },
            idV: { type: String, default: undefined}
        },
        watch: {
            value(value) {
                this.currentValue = value
            }
        },
        data() {
            return { currentValue: this.value, id: this.label }
        },
        mounted() {
            if (this.$parent.labelFor) this.$refs.input.id = this.idV || this.$parent.labelFor;
        },
        methods: {
            handleInput(e) {
                const value = e.target.value;
                this.currentValue = value;
                this.$emit('input', value);
                this.dispatch('v-form-item', 'form-change', value);
            },
            handleBlur() {
                this.dispatch('v-form-item', 'form-blur', this.currentValue);
            }
        }
    }
</script>